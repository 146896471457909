import React from "react";
import "./servis.css";

import { BiCheck } from "react-icons/bi";

const Servis = () => {
  return (
    <section id="servis">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container servis__container">
        <article className="servis">
          <div className="servis__head">
            <h3>Custom Web Development</h3>
          </div>

          <ul className="servis__list">
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Content Management System CMS Development.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Consultation and Technical Guidance.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Enhanced User Experience.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Competitive Advantage.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>BackEnd Development.</p>
            </li>
          </ul>
        </article>

        <article className="servis">
          <div className="servis__head">
            <h3>Web Development</h3>
          </div>

          <ul className="servis__list">
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Website design and development.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>E-commerce development.</p>
            </li>

            <li>
              <BiCheck className="servis__list-icon" />
              <p>Web application development.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Performance And Optimization .</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>WebSite Optamization & Security.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Website Bug Fixing.</p>
            </li>
          </ul>
        </article>

        <article className="servis">
          <div className="servis__head">
            <h3>BackEnd Development</h3>
          </div>

          <ul className="servis__list">
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Ecommerce With Customized Backend.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>APIs Development And Integration.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Migration and Upgradation.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Database Management.</p>
            </li>
            <li>
              <BiCheck className="servis__list-icon" />
              <p>Web Application Maintenance and Support.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Servis;
