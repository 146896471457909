import React, { useState } from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.png";
import IMG2 from "../../assets/portfolio2.png";
import IMG3 from "../../assets/portfolio3.jpg";
// import IMG4 from '../../assets/portfolio4.jpg'
// import IMG5 from '../../assets/portfolio5.png'
// import IMG6 from '../../assets/portfolio6.jpg'

const Portfolio = () => {
  const [data] = useState([
    {
      id: 1,
      image: IMG1,
      title: "MK IND Automation",
      github: "https://github.com/tahirturk786",
      demo: "https://mkindautomation.com",
    },
    {
      id: 2,
      image: IMG2,
      title: "MFH Apparels",
      github: "https://github.com/tahirturk786",
      demo: "https://mfhapparels.pk",
    },
    {
      id: 3,
      image: IMG3,
      title: "Dbaloch",
      github: "https://github.com/tahirturk786",
      demo: "https://dbaloch.com",
    },
    // {
    //   id: 4,
    //   image: IMG4,
    //   title: 'Crypto Currency DashBoard & Financial Visualization',
    //   github: 'https://github.com/tahirturk786',
    //   demo: 'https://legendbaba.com'
    // },
    // {
    //   id: 5,
    //   image: IMG5,
    //   title: 'Crypto Currency DashBoard & Financial Visualization',
    //   github: 'https://github.com/tahirturk786',
    //   demo: 'http://tahirturk.com'
    // },
    // {
    //   id: 6,
    //   image: IMG6,
    //   title: 'Crypto Currency DashBoard & Financial Visualization',
    //   github: 'https://github.com/tahirturk786',
    //   demo: 'http://tahirturk.com'
    // }
  ]);

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn">
                  GitHub
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
